import React, {ReactElement, useState} from "react"
import {Link, Trans, useI18next} from "gatsby-plugin-react-i18next"

const LanguageMenu: React.FC = (): ReactElement => {
  const { languages, originalPath, language: currentLanguage } = useI18next()
  const [isActive, setActive] = useState(false)

  const openMenu = () => {
    setActive(true)
  }

  const closeMenu = () => {
    setActive(false)
  }

  const onButtonClicked = (event: React.MouseEvent) => {
    ;(event.currentTarget as any).focus()
  }

  return (
    <div className="relative inline-block text-left z-10">
      <div className="z-30">
        <button
          type="button"
          className=" border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500"
          id="language-menu"
          onFocus={openMenu}
          onClick={onButtonClicked}
          onBlur={closeMenu}
        >
          <Trans>{currentLanguage}</Trans>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
          </svg>
        </button>
      </div>

      <div
        className={
          `transition z-20 ` +
          (isActive ? "skew-0 skale-1" : "skew-y-12 scale-0")
        }
        onMouseDown={event => event.preventDefault()}
      >
        <div className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
          <div
            className="py-1 "
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="language-menu"
          >
            {languages
              .filter(language => language !== currentLanguage)
              .map(language => (
                <Link
                  key={language}
                  to={originalPath}
                  language={language}
                  className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600"
                  role="menuitem"
                >
                  <span className="flex flex-col">
                    <Trans>{language}</Trans>
                  </span>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageMenu
