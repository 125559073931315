import React from "react"
import {Trans, useI18next} from "gatsby-plugin-react-i18next";

const Footer = () => {
  const { language: currentLanguage } = useI18next()

  const policyLink = currentLanguage === "de" ? "https://www.allianz.com/de/datenschutzprinzipien.html" : " https://www.allianz.com/en/privacy-statement.html";

  return (
    <footer className="snap-end border-t-gray-300 border-t">
      <div className="container mx-auto max-w-4xl flex flex-row gap-14 p-8 text-gray-500">
        <div className="font-semibold">
          © Allianz {new Date().getFullYear()}
        </div>
        <ul className="flex gap-10">
          <li><a target="_blank" href="/imprint/"><Trans>imprint</Trans></a> </li>
          <li><a target="_blank" href={policyLink} style={{wordBreak: "break-word"}}><Trans>policy</Trans></a> </li>

        </ul>
      </div>
    </footer>
  )
}
export default Footer
