/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {ReactElement} from "react"
import {graphql, useStaticQuery} from "gatsby"
import {HasChildren} from "../interfaces/HasChildren.interface"
import Footer from "./footer"
import {Helmet} from "gatsby-plugin-react-i18next"
import {isSafari} from "react-device-detect"

type LayoutProps = HasChildren

const Layout = ({ children }: LayoutProps): ReactElement => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const snapScrollRules = "snap-proximity snap-y safari:snap-none scroll-smooth"

  return (
    <>
      <div
        className={
          isSafari ? "" : snapScrollRules + " overflow-scroll h-screen"
        }
      >
        <Helmet>
          <body className={isSafari ? snapScrollRules : ""} />
        </Helmet>
        <main id="main_container">{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
